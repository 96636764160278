import React from "react"

export default function() {
  return (
    <div style={{ padding: "40px" }}>
      PRIVACY POLICY Last updated November 18, 2019 Thank you for choosing to be
      part of our community at LANG API (“Company”, “we”, “us”, or “our”). We
      are committed to protecting your personal information and your right to
      privacy. If you have any questions or concerns about our policy, or our
      practices with regards to your personal information, please contact us at
      eric@langapi.co. When you visit our website https://www.usefound.app, and
      use our services, you trust us with your personal information. We take
      your privacy very seriously. In this privacy policy, we seek to explain to
      you in the clearest way possible what information we collect, how we use
      it and what rights you have in relation to it. We hope you take some time
      to read through it carefully, as it is important. If there are any terms
      in this privacy policy that you do not agree with, please discontinue use
      of our Sites and our services. This privacy policy applies to all
      information collected through our website (such as
      https://www.usefound.app), and/or any related services, sales, marketing
      or events (we refer to them collectively in this privacy policy as the
      "Services"). Please read this privacy policy carefully as it will help you
      make informed decisions about sharing your personal information with us.
      <p>
        TABLE OF CONTENTS 1. WHAT INFORMATION DO WE COLLECT? 2. HOW DO WE USE
        YOUR INFORMATION? 3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? 4. DO
        WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 5. HOW DO WE HANDLE YOUR
        SOCIAL LOGINS? 6. HOW LONG DO WE KEEP YOUR INFORMATION? 7. DO WE COLLECT
        INFORMATION FROM MINORS? 8. WHAT ARE YOUR PRIVACY RIGHTS? 9. CONTROLS
        FOR DO-NOT-TRACK FEATURES 10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
        PRIVACY RIGHTS? 11. DO WE MAKE UPDATES TO THIS POLICY? 12. HOW CAN YOU
        CONTACT US ABOUT THIS POLICY?
      </p>
      <p>
        1. WHAT INFORMATION DO WE COLLECT? Personal information you disclose to
        us In Short: We collect personal information that you provide to us such
        as name, address, contact information, passwords and security data,
        payment information, and social media login data. We collect personal
        information that you voluntarily provide to us when registering at the
        Services expressing an interest in obtaining information about us or our
        products and services, when participating in activities on the Services
        or otherwise contacting us. The personal information that we collect
        depends on the context of your interactions with us and the Services,
        the choices you make and the products and features you use. The personal
        information we collect can include the following: Publicly Available
        Personal Information. We collect first name, maiden name, last name,
        nickname, email addresses, and other similar data. Credentials. We
        collect passwords, password hints, and similar security information used
        for authentication and account access. Social Media Login Data. We
        provide you with the option to register using social media account
        details, like your Facebook, Twitter or other social media account. If
        you choose to register in this way, we will collect the Information
        described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS"
        below. All personal information that you provide to us must be true,
        complete and accurate, and you must notify us of any changes to such
        personal information. Information automatically collected In Short: Some
        information – such as IP address and/or browser and device
        characteristics – is collected automatically when you visit our
        Services. We automatically collect certain information when you visit,
        use or navigate the Services. This information does not reveal your
        specific identity (like your name or contact information) but may
        include device and usage information, such as your IP address, browser
        and device characteristics, operating system, language preferences,
        referring URLs, device name, country, location, information about how
        and when you use our Services and other technical information. This
        information is primarily needed to maintain the security and operation
        of our Services, and for our internal analytics and reporting purposes.
        Like many businesses, we also collect information through cookies and
        similar technologies. Information collected from other sources In Short:
        We may collect limited data from public databases, marketing partners,
        social media platforms, and other outside sources. We may obtain
        information about you from other sources, such as public databases,
        joint marketing partners, social media platforms (such as Facebook), as
        well as from other third parties. Examples of the information we receive
        from other sources include: social media profile information (your name,
        gender, birthday, email, current city, state and country, user
        identification numbers for your contacts, profile picture URL, and any
        other information that you choose to make public); marketing leads and
        search results and links, including paid listings (such as sponsored
        links). We will inform you about the source of information and the type
        of information we have collected about you within a reasonable period
        after obtaining the personal data, but at the latest within one month.
      </p>
      <p>
        2. HOW DO WE USE YOUR INFORMATION? In Short: We process your information
        for purposes based on legitimate business interests, the fulfillment of
        our contract with you, compliance with our legal obligations, and/or
        your consent. We use personal information collected via our Services for
        a variety of business purposes described below. We process your personal
        information for these purposes in reliance on our legitimate business
        interests, in order to enter into or perform a contract with you, with
        your consent, and/or for compliance with our legal obligations. We
        indicate the specific processing grounds we rely on next to each purpose
        listed below. We use the information we collect or receive: To
        facilitate account creation and logon process. If you choose to link
        your account with us to a third party account (such as your Google or
        Facebook account), we use the information you allowed us to collect from
        those third parties to facilitate account creation and logon process for
        the performance of the contract. See the section below headed "HOW DO WE
        HANDLE YOUR SOCIAL LOGINS" for further information. To deliver services
        to the user. We may use your information to provide you with the
        requested service.
      </p>{" "}
      <p>
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? In Short: We only share
        information with your consent, to comply with laws, to provide you with
        services, to protect your rights, or to fulfill business obligations. We
        may process or share data based on the following legal basis: Consent:
        We may process your data if you have given us specific consent to use
        your personal information in a specific purpose. Legitimate Interests:
        We may process your data when it is reasonably necessary to achieve our
        legitimate business interests. Performance of a Contract: Where we have
        entered into a contract with you, we may process your personal
        information to fulfill the terms of our contract. Legal Obligations: We
        may disclose your information where we are legally required to do so in
        order to comply with applicable law, governmental requests, a judicial
        proceeding, court order, or legal process, such as in response to a
        court order or a subpoena (including in response to public authorities
        to meet national security or law enforcement requirements). Vital
        Interests: We may disclose your information where we believe it is
        necessary to investigate, prevent, or take action regarding potential
        violations of our policies, suspected fraud, situations involving
        potential threats to the safety of any person and illegal activities, or
        as evidence in litigation in which we are involved. More specifically,
        we may need to process your data or share your personal information in
        the following situations: Vendors, Consultants and Other Third-Party
        Service Providers. We may share your data with third party vendors,
        service providers, contractors or agents who perform services for us or
        on our behalf and require access to such information to do that work.
        Examples include: payment processing, data analysis, email delivery,
        hosting services, customer service and marketing efforts. We may allow
        selected third parties to use tracking technology on the Services, which
        will enable them to collect data about how you interact with the
        Services over time. This information may be used to, among other things,
        analyze and track data, determine the popularity of certain content and
        better understand online activity. Unless described in this Policy, we
        do not share, sell, rent or trade any of your information with third
        parties for their promotional purposes. Business Transfers. We may share
        or transfer your information in connection with, or during negotiations
        of, any merger, sale of company assets, financing, or acquisition of all
        or a portion of our business to another company. Third-Party
        Advertisers. We may use third-party advertising companies to serve ads
        when you visit the Services. These companies may use information about
        your visits to our Website(s) and other websites that are contained in
        web cookies and other tracking technologies in order to provide
        advertisements about goods and services of interest to you.
      </p>
      <p>
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? In Short: We may
        use cookies and other tracking technologies to collect and store your
        information. We may use cookies and similar tracking technologies (like
        web beacons and pixels) to access or store information. Specific
        information about how we use such technologies and how you can refuse
        certain cookies is set out in our Cookie Policy.
      </p>{" "}
      <p>
        5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? In Short: If you choose to
        register or log in to our services using a social media account, we may
        have access to certain information about you. Our Services offer you the
        ability to register and login using your third party social media
        account details (like your Facebook or Twitter logins). Where you choose
        to do this, we will receive certain profile information about you from
        your social media provider. The profile Information we receive may vary
        depending on the social media provider concerned, but will often include
        your name, e-mail address, friends list, profile picture as well as
        other information you choose to make public. We will use the information
        we receive only for the purposes that are described in this privacy
        policy or that are otherwise made clear to you on the Services . Please
        note that we do not control, and are not responsible for, other uses of
        your personal information by your third party social media provider. We
        recommend that you review their privacy policy to understand how they
        collect, use and share your personal information, and how you can set
        your privacy preferences on their sites and apps.
      </p>
      <p>
        6. HOW LONG DO WE KEEP YOUR INFORMATION? In Short: We keep your
        information for as long as necessary to fulfill the purposes outlined in
        this privacy policy unless otherwise required by law. We will only keep
        your personal information for as long as it is necessary for the
        purposes set out in this privacy policy, unless a longer retention
        period is required or permitted by law (such as tax, accounting or other
        legal requirements). No purpose in this policy will require us keeping
        your personal information for longer than the period of time in which
        users have an account with us. When we have no ongoing legitimate
        business need to process your personal information, we will either
        delete or anonymize it, or, if this is not possible (for example,
        because your personal information has been stored in backup archives),
        then we will securely store your personal information and isolate it
        from any further processing until deletion is possible.
      </p>{" "}
      <p>
        7. DO WE COLLECT INFORMATION FROM MINORS? In Short: We do not knowingly
        collect data from or market to children under 18 years of age. We do not
        knowingly solicit data from or market to children under 18 years of age.
        By using the Services, you represent that you are at least 18 or that
        you are the parent or guardian of such a minor and consent to such minor
        dependent’s use of the Services. If we learn that personal information
        from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete
        such data from our records. If you become aware of any data we have
        collected from children under age 18, please contact us at
        eric@langapi.co.
      </p>
      <p>
        8. WHAT ARE YOUR PRIVACY RIGHTS? In Short: You may review, change, or
        terminate your account at any time. If you are resident in the European
        Economic Area and you believe we are unlawfully processing your personal
        information, you also have the right to complain to your local data
        protection supervisory authority. You can find their contact details
        here:
        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        Account Information If you would at any time like to review or change
        the information in your account or terminate your account, you can: ■
        Contact us using the contact information provided. Upon your request to
        terminate your account, we will deactivate or delete your account and
        information from our active databases. However, some information may be
        retained in our files to prevent fraud, troubleshoot problems, assist
        with any investigations, enforce our Terms of Use and/or comply with
        legal requirements. Cookies and similar technologies: Most Web browsers
        are set to accept cookies by default. If you prefer, you can usually
        choose to set your browser to remove cookies and to reject cookies. If
        you choose to remove cookies or reject cookies, this could affect
        certain features or services of our Services. To opt-out of
        interest-based advertising by advertisers on our Services visit
        http://www.aboutads.info/choices/. Opting out of email marketing: You
        can unsubscribe from our marketing email list at any time by clicking on
        the unsubscribe link in the emails that we send or by contacting us
        using the details provided below. You will then be removed from the
        marketing email list – however, we will still need to send you
        service-related emails that are necessary for the administration and use
        of your account. To otherwise opt-out, you may:
      </p>{" "}
      <p>
        9. CONTROLS FOR DO-NOT-TRACK FEATURES Most web browsers and some mobile
        operating systems and mobile applications include a Do-Not-Track (“DNT”)
        feature or setting you can activate to signal your privacy preference
        not to have data about your online browsing activities monitored and
        collected. No uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not
        currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the
        future, we will inform you about that practice in a revised version of
        this privacy policy.
      </p>
      <p>
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? In Short: Yes,
        if you are a resident of California, you are granted specific rights
        regarding access to your personal information. California Civil Code
        Section 1798.83, also known as the “Shine The Light” law, permits our
        users who are California residents to request and obtain from us, once a
        year and free of charge, information about categories of personal
        information (if any) we disclosed to third parties for direct marketing
        purposes and the names and addresses of all third parties with which we
        shared personal information in the immediately preceding calendar year.
        If you are a California resident and would like to make such a request,
        please submit your request in writing to us using the contact
        information provided below. If you are under 18 years of age, reside in
        California, and have a registered account with the Services, you have
        the right to request removal of unwanted data that you publicly post on
        the Services. To request removal of such data, please contact us using
        the contact information provided below, and include the email address
        associated with your account and a statement that you reside in
        California. We will make sure the data is not publicly displayed on the
        Services, but please be aware that the data may not be completely or
        comprehensively removed from our systems.
      </p>
      <p>
        11. DO WE MAKE UPDATES TO THIS POLICY? In Short: Yes, we will update
        this policy as necessary to stay compliant with relevant laws. We may
        update this privacy policy from time to time. The updated version will
        be indicated by an updated “Revised” date and the updated version will
        be effective as soon as it is accessible. If we make material changes to
        this privacy policy, we may notify you either by prominently posting a
        notice of such changes or by directly sending you a notification. We
        encourage you to review this privacy policy frequently to be informed of
        how we are protecting your information.
      </p>{" "}
      12. HOW CAN YOU CONTACT US ABOUT THIS POLICY? If you have questions or
      comments about this policy, you may email us at eric@langapi.co or by post
      to: LANG API 1932 Powell Street San Francisco, CA 94133 United States HOW
      CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? Based on
      the laws of some countries, you may have the right to request access to
      the personal information we collect from you, change that information, or
      delete it in some circumstances. To request to review, update, or delete
      your personal information, please submit a request form by clicking here.
      We will respond to your request within 30 days.
    </div>
  )
}
